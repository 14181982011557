
// @ is an alias to /src
import { defineComponent } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import router from "@/router";

export default defineComponent({
  name: "PasswrordNewSuccess",
  components: {
    FlatButton,
  },
  methods: {
    goLogin() {
      router.push({ name: "Login" });
    },
  },
});
